<template>
    <div class="enterpriseNews">
        <div class="banner f-cl-c-c">

        </div>
        <div class="Part f-c-b">
            <div class="LeftPart f-c" @click="back()">
                <img src="@/assets/supply/return.png" alt="">
                <span>返回</span>
            </div>
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/SupplyIndex' }">供应</el-breadcrumb-item>
                <el-breadcrumb-item>消息</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="list" v-if="NewList && NewList.length != 0">
            <div class="item f-c-b" v-for="(item, index) in NewList" :key="index">
                <div class="flex">
                    <img v-if="item.eimage1" class="NewImg" :src="item.eimage1" alt="">
                    <div v-else class="f-c-c NewImg" style="background: #D9ECFB;">
                        <img  class="" src="@/assets/supply/pic.png" alt="">
                    </div>
                    
                    <div class="info f-cl-b">
                        <div class="applyType">{{ item.applyTypeNext }}</div>
                        <div class="enterpriseName">{{ item.enterpriseName }}</div>
                        <div class="PromptBox"
                            :class="item.PromptBoxStyle == 'blue' ? 'blue' : item.PromptBoxStyle == 'yellow' ? 'yellow' : 'red'">
                            <i class="el-icon-warning-outline" style=""></i>
                            {{ item.PromptBoxText }}
                        </div>
                    </div>
                </div>
                <div class="BtnBox flex">
                    <div @click="userRevokeJoinEnterprise(item.applyId)"
                        v-if="item.applyType == 'J' && item.handleFlag == 'W'" class="blue">撤销加入</div>
                    <div @click="userApprovalEmptyOne(item.applyId)"
                        v-else-if="item.applyType == 'J' && item.handleFlag == 'S'" class="blue">清除消息
                    </div>
                    <div v-else-if="item.applyType == 'I'" class="flex">
                        <div @click="userAgreeJoinEnterprise(item.applyId)" class="blue">同意</div>
                        <div @click="userRefuseJoinEnterprise(item.applyId)" class="white">拒绝</div>
                    </div>

                    <div @click="userApprovalEmptyOne(item.applyId)" v-else class="blue">清除消息</div>
                </div>
            </div>
            <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
                @pagination="fetchData" />
        </div>

    </div>
</template>

<script>
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin'

export default {
    name: 'EnterpriseNews',
    mixins: [PaginationRetention],
    data() {
        return {
            NewList: []
        }
    },
    created() {
        // this.userFindJoinEnterprise();
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        getDate() {
            this.userFindJoinEnterprise(this.paging, this.pagingSize)
        },
        // 刷新当前页面
        refresh() {
            this.getDate();
        },
        // 用户查看申请或邀请加入企业列表接口
        userFindJoinEnterprise(page = this.page, size = this.size) {
            let that = this;
            that.$http.userFindJoinEnterprise({
                applyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data.records
                    records.forEach((item) => {
                        if (item.applyType == 'J') {
                            item.applyTypeNext = '申请加入';
                        } else if (item.applyType == 'I') {
                            item.applyTypeNext = '邀请加入';
                        }
                        else if (item.applyType == 'Q' && item.handleFlag == 'S' && item.result == 'Y') {
                            item.applyTypeNext = '退出企业';
                        }
                        else if (item.applyType == 'D') {
                            item.applyTypeNext = '管理员移出';
                        }
                        else if (item.applyType == 'XQ') {
                            item.applyTypeNext = '退出企业';
                        }
                        else if (item.applyType == 'XD') {
                            item.applyTypeNext = '企业已解散';
                        }

                        if (item.applyType == 'J' && item.handleFlag == 'W') {
                            item.PromptBoxStyle = 'yellow';
                            item.PromptBoxText = '您申请加入该公司，待公司管理员审核！';
                        } else if (item.applyType == 'I') {
                            item.PromptBoxStyle = 'blue';
                            item.PromptBoxText = '邀请您加入，请处理！';
                        } else {
                            item.PromptBoxStyle = 'red';
                            if (item.applyType == 'J' && item.handleFlag == 'S') {
                                item.PromptBoxText = '您加入该公司的申请，管理员已拒绝！';
                            } else if (item.applyType == 'Q' && item.handleFlag == 'S' && item.result == 'Y') {
                                item.PromptBoxText = '您退出该公司！';
                            } else if (item.applyType == 'D' && item.handleFlag == 'S') {
                                item.PromptBoxText = '管理员已经将你移出企业！';
                            } else if (item.applyType == 'XD' && item.handleFlag == 'S') {
                                item.PromptBoxText = '该企业已解散，您已退出该企业。';
                            } else if (item.applyType == 'XQ' && item.handleFlag == 'S') {
                                item.PromptBoxText = '该企业解散中，您已主动退出该企业。';
                            }
                        }


                    })
                    this.currentPage = res.data.current;//当前页数
                    this.total = res.data.total;
                    this.NewList = records;
                }
            })
        },
        // 用户清空指定消息
        userApprovalEmptyOne(applyId) {
            let that = this;
            that.$confirm('是否清除当前消息？', '提示').then(() => {
                that.$http.userApprovalEmptyOne({
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    applyId
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.refresh();
                        if(that.NewList.length == 0){
                            that.$common.DelayedBack();
                        }
                    }
                })
            }).catch(() => { })
        },
        // 用户同意加入企业接口
        userAgreeJoinEnterprise(applyId) {
            let that = this;
            that.$confirm('确定加入该企业', '提示').then(() => {
                that.$http.userAgreeJoinEnterprise({
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    applyId
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBack();
                    }
                })
            }).catch(() => { })
        },
        // 用户拒绝加入企业接口
        userRefuseJoinEnterprise(applyId) {
            let that = this;
            that.$confirm('拒接加入该企业', '提示').then(() => {
                that.$http.userRefuseJoinEnterprise({
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    applyId
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBack();
                        that.refresh()
                    }
                })
            }).catch(() => { })
        },
        // 用户撤销加入企业或用户撤销退出企业接口
        userRevokeJoinEnterprise(applyId) {
            let that = this;
            that.$confirm('撤销加入', '提示').then(() => {
                that.$http.userRevokeJoinEnterprise({
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    applyId
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBack();
                    }
                })
            }).catch(() => { })
        },


    }
}
</script>
<style lang="less" scoped>
.enterpriseNews {
    .banner {
        background: url('../../../assets/supply/bannerSupplyIndex.png');
        height: 200px;
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 40px;

        .name {
            font-size: 36px;
        }

        .state {
            font-size: 28px;
        }
    }

    .Part {
        padding: 20px 0;

        .LeftPart {
            width: 55px;
            cursor: pointer;

            img {
                margin-right: 5px;
            }

            span {
                color: #0E6AFA;
                font-size: 16px;
            }

        }

        .el-breadcrumb ::v-deep .el-breadcrumb__inner {
            color: #666666 !important;
            font-weight: 400 !important;
            font-size: 14px;
        }

        .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
            color: #0363FA !important;
            font-weight: 400 !important;
            font-size: 14px;
        }
    }

    .list {
        padding: 30px 190px;
        border-top: 1px solid #FBFBFB;

        .item {
            padding: 20px 30px;
            background: #F8F8F8;
            border-radius: 10px;
            margin-bottom: 20px;

            .NewImg {
                width: 120px;
                height: 120px;
                border-radius: 10px;
                margin-right: 40px;
            }

            .info {
                padding: 10px 0;

                .applyType {
                    font-size: 24px;
                    font-weight: 400;
                    color: #000000;
                }

                .enterpriseName {
                    font-size: 16px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 400;
                    color: #999999;
                }

                .PromptBox {
                    padding: 8px 12px;
                    border-radius: 10px;
                    width: 310px;
                }

                .blue {
                    background: #DFF2F9;
                    color: #00BAFF;
                }

                .yellow {
                    background: #F9EFDF;
                    color: #FD9D00;
                }

                .red {
                    background: #F9E4DF;
                    color: #FF3500;
                }
            }

            .BtnBox {
                .blue {
                    width: 120px;
                    height: 40px;
                    border-radius: 10px;
                    background: linear-gradient(79deg, #0873F4 0%, #39A8F9 100%);
                    text-align: center;
                    line-height: 40px;
                    color: #FFF;
                    font-size: 16px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 400;
                    cursor: pointer;
                }

                .white {
                    width: 120px;
                    height: 40px;
                    border-radius: 10px;
                    background: #FFF;
                    border: 1px solid #4A8DD2;
                    text-align: center;
                    line-height: 40px;
                    font-size: 16px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 400;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
        }

    }

}
</style>